import React from "react";
import "./bannertitle.css";
import programBannerPhoto from "assets/teamphotobannercopy.webp";

const BannerTitle = (props) => {
  // Eventually make image a prop and this will be a reusable component with dynamic image
  return (
    <div className="banner-title-container">
      <h1 className="program-centered-title">{props.title}</h1>
      <img
        alt="ace_gymnastics_graphic"
        src={programBannerPhoto}
        width="100%"
        height="auto"
      />
    </div>
  );
};

// BannerTitle.defaultProps = {
//   title: "Programs",
// };

export default BannerTitle;
