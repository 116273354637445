export const SET_JACKRABBIT_PROGRAMS = "SET_JACKRABBIT_PROGRAMS";

export function setJackRabbitPrograms(request) {
  return {
    type: SET_JACKRABBIT_PROGRAMS,
    payload: request,
  };
}

// need a loading indicator for each one of the programs. So just having an isLoading is not enough. Will need isPreschoolLoading. That, or I should just handle this in the state of component
