import React from "react";
import "./programSection.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ProgramSection = () => {
  let contentfulProgramsSection = useSelector(
    (state) => state.contentfulReducer.programTitles
  );

  function scrollToTop(event) {
    event.preventDefault();
    window.scrollTo(0, 0);
    // Navigate to the link path after the scroll occurs
    window.location.href = event.currentTarget.href;
  }

  return (
    <div className="home-program">
      <div className="program-section">
        {contentfulProgramsSection.map((programSection, key) => {
          return (
            <div
              key={key}
              style={{
                width: `${100 / contentfulProgramsSection.length - 2}%`,
              }}
              className="program-image"
            >
              <img
                src={programSection.fields.image.fields.file.url}
                alt="gymnastics"
                width="600"
                height="600"
              />
              <Link
                onClick={(event) => scrollToTop(event)}
                style={{ textDecoration: "none" }}
                to={`/programs/program-details/${programSection.fields.displayName}`}
              >
                <h3>{programSection.fields.name}</h3>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgramSection;
