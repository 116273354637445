import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import BannerTitle from "components/BannerTitle/bannertitle";
import "./jobApplication.css";
import { Button } from "@material-ui/core";

const JobApplication = () => {
  const jobApplication = useSelector((state) => state.contentfulReducer.job);

  const renderRichText = (richTextDocument) => {
    return richTextDocument.content.map((node, index) => {
      switch (node.nodeType) {
        case "paragraph":
          return <p key={index}>{node.content[0].value}</p>;
        case "unordered-list":
          return (
            <ul key={index}>
              {node.content.map((listItem, listItemIndex) => (
                <li key={listItemIndex}>
                  {listItem.content[0].content[0].value}
                </li>
              ))}
            </ul>
          );
        case "ordered-list":
          return (
            <ol key={index}>
              {node.content.map((listItem, listItemIndex) => (
                <li key={listItemIndex}>
                  {listItem.content[0].content[0].value}
                </li>
              ))}
            </ol>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div className="job-application-page">
      <Helmet>
        <title>Ace Gymnastics Job Application</title>
        <meta name="description" content="Apply for a job at Ace Gymnastics" />
        <meta property="og:title" content="Ace Gymnastics Job Application" />
        <meta
          property="og:description"
          content="Apply for a job at Ace Gymnastics"
        />
        <meta property="og:image" content="https://i.imgur.com/UKJjqNH.png" />
        <meta
          property="og:url"
          content="https://www.ace-gymnastics.com/job-application"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <BannerTitle title="Employment Application" />
      <div className="job-details">
        <h1>{jobApplication.jobTitle}</h1>
        <p>
          <strong>Location:</strong> {jobApplication.location}
        </p>
        <h2>Description</h2>
        <p>{jobApplication.description}</p>
        <h2>Responsibilities</h2>
        {renderRichText(jobApplication.responsibilities)}
        <h2>Qualifications</h2>
        {renderRichText(jobApplication.qualifications)}
        <h2>Working Hours</h2>
        {renderRichText(jobApplication.workingHours)}
        <h2>Compensation</h2>
        {renderRichText(jobApplication.compensation)}
        <div className="button-container">
          <Button
            variant="contained"
            href="https://docs.google.com/forms/d/e/1FAIpQLScEsZWrretOPpwmS9sLAyA_6slt4f6v9Gc1C4DemjRaR0Q7-A/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JobApplication;
