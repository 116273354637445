import {
  SET_CONTENTUL_PROGRAMS,
  SET_CONTENTUL_STAFF,
  SET_CONTENTFUL_EVENTS,
  SET_CONTENTFUL_CAREERS,
  SET_CONTENTFUL_JOB,
  SET_MEET_CARDS,
} from "../actions/contentfulActions";

const initialState = {
  programTitles: [],
  staff: [],
  events: [],
  career: {
    title: "",
    sections: [],
  },
  job: undefined,
  meetCards: [],
};

const contentfulReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTENTUL_PROGRAMS:
      return { ...state, programTitles: action.payload.items };
    case SET_CONTENTUL_STAFF:
      return { ...state, staff: action.payload.items };
    case SET_CONTENTFUL_EVENTS:
      return { ...state, events: action.payload.items };
    case SET_CONTENTFUL_CAREERS:
      return { ...state, career: action.payload };
    case SET_CONTENTFUL_JOB:
      return { ...state, job: action.payload.items[0].fields };
    case SET_MEET_CARDS:
      return { ...state, meetCards: action.payload };
    default:
      return state;
  }
};

export default contentfulReducer;
