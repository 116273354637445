import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./midPageBreak.css";

const MidPageBreak = ({ title, buttonContent }) => {
  const scrollToTopAndCloseNavBar = async () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="midPage-container">
      {title}
      <Button
        variant="contained"
        component={Link}
        to={buttonContent.href}
        target={buttonContent.target}
        rel={buttonContent.rel}
        onClick={scrollToTopAndCloseNavBar}
      >
        {buttonContent.title}
      </Button>
    </div>
  );
};

export default MidPageBreak;
