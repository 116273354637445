export const SET_CONTENTUL_PROGRAMS = "SET_CONTENTUL_PROGRAMS";
export const SET_CONTENTUL_STAFF = "SET_CONTENTUL_STAFF";
export const SET_CONTENTFUL_EVENTS = "SET_CONTENTFUL_EVENTS";
export const SET_CONTENTFUL_CAREERS = "SET_CONTENTFUL_CAREERS";
export const SET_CONTENTFUL_JOB = "SET_CONTENTFUL_JOB";
export const SET_MEET_CARDS = "SET_MEET_CARDS";

export function setContentfulPrograms(response) {
  return {
    type: SET_CONTENTUL_PROGRAMS,
    payload: response,
  };
}

export function setContentfulStaff(response) {
  return {
    type: SET_CONTENTUL_STAFF,
    payload: response,
  };
}

export function setContentfulEvents(response) {
  return {
    type: SET_CONTENTFUL_EVENTS,
    payload: response,
  };
}

export function setContentfulCareers(response) {
  return {
    type: SET_CONTENTFUL_CAREERS,
    payload: response,
  };
}

export function setContentfulJob(response) {
  return {
    type: SET_CONTENTFUL_JOB,
    payload: response,
  };
}

export function setContentfulMeetCards(response) {
  return {
    type: SET_MEET_CARDS,
    payload: response,
  };
}
