import React, { useState, useEffect } from "react";
import teamphoto from "assets/teamphotocopy.webp";
import teamphotomobile from "assets/teamphotomobile.webp";
import mobileMeetHero from "assets/mobileMeetHero.png";
import meetHero from "assets/meetHero.png";
import "components/Carousel/carousel.css";
import LCPImage from "components/LCPImage/lcpImage";

const CarouselJumbo = ({ meetBanner }) => {
  const [imageSrc, setImageSrc] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 449) {
        setImageSrc(meetBanner ? mobileMeetHero : teamphotomobile);
      } else {
        setImageSrc(meetBanner ? meetHero : teamphoto);
      }
      setWidth(screenWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [meetBanner]);

  return (
    <div>
      <LCPImage
        src={imageSrc}
        alt="carousel"
        className="carousel-image"
        width={width > 449 ? "1200" : "450"}
        height={width > 449 ? "600" : "563"}
      />
    </div>
  );
};

export default CarouselJumbo;
