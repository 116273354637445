import { createClient } from "contentful";
import {
  setContentfulCareers,
  setContentfulEvents,
  setContentfulJob,
  setContentfulMeetCards,
  setContentfulPrograms,
  setContentfulStaff,
} from "store/actions/contentfulActions";
import { store } from "store/store";

const useContentful = () => {
  const client = createClient({
    space: "8xwfyz9o0vu9",
    accessToken: "M9I5ny01QN3dHYvWOSsh21VC2xSeGR8k6alKpn0CWmA",
    host: "cdn.contentful.com",
  });

  const getContentfulPrograms = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "programTitles",
        select: "fields",
        order: "fields.name",
      });
      store.dispatch(setContentfulPrograms(entries));
    } catch (error) {
      console.log(`Error Fetching Programs:${error}`);
    }
  };

  const getContentfulStaff = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "staffMembers",
        select: "fields",
        order: "fields.order",
      });
      store.dispatch(setContentfulStaff(entries));
    } catch (error) {
      console.log(`Error Fetching Staff:${error}`);
    }
  };

  const getContentfulEvents = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "events",
        select: "fields",
        order: "fields.order",
      });
      store.dispatch(setContentfulEvents(entries));
    } catch (error) {
      console.log(`Error Fetching Events:${error}`);
    }
  };

  const getContentfulCareers = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "careerPage",
        select: "fields",
        include: 2, // This includes up to 2 levels of linked entries
      });
      // Process the entries to include resolved links
      const careerPage = entries.items[0].fields;
      const sections = careerPage.sections.map((section) => {
        const linkedEntry = entries.includes.Entry.find(
          (e) => e.sys.id === section.sys.id
        );
        return linkedEntry ? linkedEntry.fields : section;
      });
      const processedData = {
        title: careerPage.title,
        sections,
      };
      store.dispatch(setContentfulCareers(processedData));
    } catch (error) {
      console.log(`Error Fetching Careers: ${error}`);
    }
  };

  const getContentfulJob = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "job",
        select: "fields",
      });
      store.dispatch(setContentfulJob(entries));
    } catch (error) {
      console.log(`Error Fetching Events:${error}`);
    }
  };

  const getContentfulMeetCards = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "meetCardContainer",
        select: "fields",
      });
      store.dispatch(setContentfulMeetCards(entries.items[0].fields.cards));
    } catch (error) {
      console.log(`Error Fetching Events:${error}`);
    }
  };

  return {
    getContentfulPrograms,
    getContentfulStaff,
    getContentfulEvents,
    getContentfulCareers,
    getContentfulJob,
    getContentfulMeetCards,
  };
};

export default useContentful;
