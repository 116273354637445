import EventsCard from "components/EventsCard/eventsCard";
import BannerTitle from "components/BannerTitle/bannertitle";
import "./events.css";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";

const Events = () => {
  const events = useSelector((state) => state.contentfulReducer.events);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Ace Gymnastics Events</title>
        <meta
          name="description"
          content="Explore upcoming gymnastics events at Ace Gymnastics. Join us for competitions, open gyms, and more!"
        />
        <meta property="og:title" content="Ace Gymnastics Events" />
        <meta
          property="og:description"
          content="Explore upcoming gymnastics events at Ace Gymnastics. Join us for competitions, open gyms, and more!"
        />
        <meta property="og:image" content="https://i.imgur.com/UKJjqNH.png" />
        <meta
          property="og:url"
          content="https://www.ace-gymnastics.com/events"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <BannerTitle title="Events" />
      <div className="events-responsive events-container">
        {events.map((event, key) => {
          return (
            <div key={key} className="events-card-container">
              <EventsCard events={event} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Events;
