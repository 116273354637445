import React, { useEffect, useState } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "containers/Home/Home";
import Footer from "components/Footer/footer";
import Copyright from "components/Footer/copyright";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";
import ProgramDetails from "components/ProgramDetails/programDetails";
import NavBar from "components/NavBar/navbar";
import useContentful from "hooks/useContentful";
import { setSelectedProgram } from "store/actions/navigationActions";
import Staff from "containers/Staff/Staff";
import Events from "components/Events/events";
import CareerPage from "components/CareerPage/careerPage";
import JobApplication from "components/JobApplication/jobApplication";
// import Meet from "components/MeetPage/meet";

const App = () => {
  const [shouldFadeIn, setShouldFadeIn] = useState(false);
  const {
    getContentfulStaff,
    getContentfulPrograms,
    getContentfulEvents,
    getContentfulCareers,
    getContentfulJob,
    getContentfulMeetCards,
  } = useContentful();
  const dispatch = useDispatch();

  const initializeReactGA = () => {
    ReactGA.pageview("/");
  };

  useEffect(() => {
    initializeReactGA();
    getContentfulStaff();
    getContentfulPrograms();
    getContentfulEvents();
    getContentfulCareers();
    getContentfulJob();
    getContentfulMeetCards();
    return () => {
      dispatch(setSelectedProgram(""));
    };
  }, [
    dispatch,
    getContentfulPrograms,
    getContentfulStaff,
    getContentfulEvents,
    getContentfulCareers,
    getContentfulJob,
    getContentfulMeetCards,
  ]);

  useEffect(() => {
    const alreadyVisited = localStorage.getItem("alreadyVisited");
    if (!alreadyVisited) {
      localStorage.setItem("alreadyVisited", true);
      setShouldFadeIn(true);
    }
  }, []);

  return (
    <>
      <div className={`App ${shouldFadeIn ? "fade-in" : ""}`}>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/programs" element={<ProgramContainer />} /> */}
          <Route
            exact={false}
            path="/programs/program-details/*"
            element={<ProgramDetails />}
          />
          <Route path="/staff" element={<Staff />} />
          <Route path="/events" element={<Events />} />
          <Route path="/employment" element={<CareerPage />} />
          <Route path="/job-application" element={<JobApplication />} />
          {/* <Route path="/bourbon-city-spectacular" element={<Meet />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
        <Copyright />
        {/* <AnalyticsConsent /> */}
      </div>
    </>
  );
};

export default App;
