import { SET_SELECTED_PROGRAM } from "../actions/navigationActions";

const initialState = {
  selectedProgram: "",
  displayProgramNameWithAppendedClassText: "",
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PROGRAM:
      return {
        ...state,
        selectedProgram: action.payload,
        displayProgramNameWithAppendedClassText: `${action.payload} ${
          action.payload === "Tumbling" ? "" : "Gymnastics"
        }`,
      };
    default:
      return state;
  }
};

export default navigationReducer;
