import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { convertContentfulNotesFromString } from "helpers/dataConversion/convertContentfulNotesFromString";
import logo from "assets/logo.webp";
import "./eventsCard.css";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function EventsCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    // ReactGA.event({
    //   category: "Events Card",
    //   action: "Click",
    //   label: props.events.fields.name,
    //   eventAction: props.events.fields.name,
    // });
    setExpanded(!expanded);
  };

  console.log({ props });

  return (
    <Card sx={{ maxWidth: 345, minHeight: 472 }} raised={true}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "rgb(160, 36, 36)" }}>
            <img className="avatar-img" src={logo} alt="logo" />
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={props.events.fields.name}
        style={{ color: "rgb(160, 36, 36)" }}
        // subheader="September 14, 2016"
      />
      <CardMedia
        component="img"
        height="194"
        image={props.events.fields.media?.fields.file.url}
        alt="Event Picture"
      />
      <CardContent
        style={{ maxHeight: !expanded ? "150px" : null, overflow: "hidden" }}
      >
        <Typography variant="body2" color="text.primary">
          {props.events.fields.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ maxHeight: "400px", overflow: "scroll" }}>
          {props.events.fields.date && (
            <Typography paragraph variant="body2">
              <p>
                <b>Date: </b>
                {props.events.fields.date}
              </p>
            </Typography>
          )}
          {props.events.fields.time && (
            <Typography paragraph variant="body2">
              <p>
                <b>Time: </b>
                {props.events.fields.time}
              </p>
            </Typography>
          )}
          {props.events.fields.price && (
            <Typography paragraph variant="body2">
              <p>
                <b>Price: </b>
                {props.events.fields.price}
              </p>
            </Typography>
          )}
          {props.events.fields.spectatorPrice && (
            <Typography paragraph variant="body2">
              <p>
                <b>Spectator Price: </b>
                {props.events.fields.spectatorPrice}
              </p>
            </Typography>
          )}
          {props.events.fields.registrationLink && (
            <Typography paragraph variant="body2">
              <p>
                <a
                  href={props.events.fields.registrationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="register for event"
                >
                  Register
                </a>
              </p>
            </Typography>
          )}
          {props.events.fields.notes && (
            <Typography paragraph variant="body2">
              <p style={{ margin: "0px" }}>
                <b>Other Information:</b>
              </p>
              {convertContentfulNotesFromString(props.events.fields.notes).map(
                (note, key) => (
                  <div key={key}>
                    <br />
                    <em>{note}</em>
                  </div>
                )
              )}
            </Typography>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
}
