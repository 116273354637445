import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./programDescription.css";
import { fetchPrograms } from "helpers/jackrabbit/fetchPrograms";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProgram } from "store/actions/navigationActions";
import ProgramDetailCard from "components/ProgramDetailCard/programDetailCard";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const ProgramDescription = () => {
  const { programtype } = useParams();
  const programTitles = useSelector(
    (state) => state.contentfulReducer.programTitles
  );
  const dispatch = useDispatch();
  const currentProgram = programTitles.find(
    (element) => element.fields.displayName === programtype
  );
  useEffect(() => {
    if (programtype) dispatch(setSelectedProgram(programtype));
    // if programType is equal to Competitive Gymnastics, we will only display description and name
    fetchPrograms({ Cat1: currentProgram.fields.jackrabbitSlug });
    return () => {
      dispatch(setSelectedProgram(""));
    };
  }, [programtype, currentProgram.fields.jackrabbitSlug, dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  // TODO:
  // Need to create component to handle the Cat2 sections. For example, Preschool Gymnastics has Parent-Tot Cat2.
  // My thought is to update the JSON Object for every Program in contentful that would be an array with an object with keys of displayName, jackrabbitcat2slug and description. We would use this
  // to display the name and description since there are multiple cat2 to every cat1.
  // For the table component, we can pass it data that matches jackrabbitcat2slug from contentful to cat2.

  // Step 1: Use the data from programTitles in contentful to display the program description. This is currently selected as currentProgram on line 12.
  // Step 2: Create a loop of the jackrabbit programs and pass them to the child component if they match the jackrabbitcat2slug. This component will house the cat2 description as
  // well as the custom table. This component will also be passed a displayName and description.

  return (
    <div className="program-description-parent">
      <Helmet>
        <title>{`Ace Gymnastics - ${currentProgram.fields.displayName}`}</title>
        <meta
          name={`Ace Gymnastics - ${currentProgram.fields.displayName}`}
          content={`Ace Gymnastics - ${currentProgram.fields.displayName} ${currentProgram.fields.ProgramDescription}`}
        />
      </Helmet>
      <div className="program-responsive program-description-container">
        <h1 className="program-description-header">{`${
          currentProgram.fields.displayName
        } ${
          currentProgram.fields.displayName === "Tumbling" ? "" : "Gymnastics"
        }`}</h1>
        <p>{currentProgram.fields.description}</p>
      </div>
      {currentProgram.fields.jackrabbitCat2.map((cat2, key) => {
        return (
          <ProgramDetailCard
            key={key}
            count={key}
            jackrabbitSlug={currentProgram.fields.jackrabbitSlug}
            jackrabbitCat2={cat2.jackrabbitCat2Slug}
            cat2Description={cat2.description}
            categoryTitle={cat2.displayName}
          />
        );
      })}
    </div>
  );
};

// Can loop through reusable component. The component can query for jackrabbit based on the end of the URL. We can make sure to use the jackrabbitSlug

export default ProgramDescription;
