import React from "react";

const Copyright = () => {
  return (
    <div className="copyright">
      <p>
        {`Website designed and built by `}
        <a
          href="https://www.bourboncitywebdevelopment.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Bourbon City Web Development website in a new tab"
        >
          Bourbon City Web Development
        </a>
        .
      </p>
    </div>
  );
};

export default Copyright;
