export const convertURLEncoded = (string) => {
  // check if string is undefined
  if (string === undefined) {
    return;
  }
  const url = string;
  // check if string is a URL
  if (!url.includes("http")) {
    return;
  }

  const decodedUrl = decodeURIComponent(url.replace(/&amp;/g, "%26"));

  const urlObj = new URL(decodedUrl);

  const params = {};

  for (const [key, value] of urlObj.searchParams) {
    params[key] = value;
  }

  const decodedParams = {};

  for (const [key, value] of Object.entries(params)) {
    decodedParams[key] = decodeURIComponent(value);
  }

  const newSearchParams = new URLSearchParams(decodedParams);

  const newUrl = `${urlObj.origin}${
    urlObj.pathname
  }?${newSearchParams.toString()}`;

  return newUrl;
};
