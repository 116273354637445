import axios from "axios";
import { store } from "../../store/store";
import { setJackRabbitPrograms } from "store/actions/jackRabbitActions";

export const fetchPrograms = (filters) => {
  return axios({
    method: "POST",
    url: "https://ace-gymnastics-api.herokuapp.com/programs",
    // url: "http://localhost:9000/programs",
    // url: "https://app.jackrabbitclass.com/jr3.0/Openings/OpeningsJson",
    data: {
      OrgId: process.env.REACT_APP_ORGID,
      ShowClosed: true,
      ...filters,
    },
  })
    .then((result) => {
      store.dispatch(setJackRabbitPrograms(result.data.rows));
    })
    .catch((error) => {
      console.log("catch", error);
    });
};
