import CustomizedTables from "components/ProgramsTable/programsTable";
import { fetchCat2Programs } from "helpers/jackrabbit/fetchCat2Programs";
import { useCallback, useEffect, useState } from "react";
import "./programDetailCard.css";

const ProgramDetailCard = (props) => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCategories = useCallback(async () => {
    const response = await fetchCat2Programs({
      Cat1: props.jackrabbitSlug,
      Cat2: props.jackrabbitCat2,
    });
    setCategories(response);
    setIsLoading(false);
  }, [props.jackrabbitSlug, props.jackrabbitCat2]);

  useEffect(() => {
    fetchCategories();
  }, [props.jackrabbitSlug, props.jackrabbitCat2, fetchCategories]);

  const renderJsx = () => {
    if (isLoading) {
      return <h3>Loading...</h3>;
    } else if (categories.length > 0) {
      return <CustomizedTables rows={categories} />;
    } else return <h3>No classes available at this time.</h3>;
  };

  return (
    <div
      style={{
        backgroundColor:
          props.count % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "white",
      }}
    >
      <div className="program-responsive program-detail-card-parent">
        <div className="program-detail-card-container">
          <h2>{props.categoryTitle}</h2>
          <div>{props.cat2Description}</div>
        </div>
        <div className="custom-table-parent">{renderJsx()}</div>
      </div>
    </div>
  );
};

export default ProgramDetailCard;
