import { SET_JACKRABBIT_PROGRAMS } from "../actions/jackRabbitActions";

const initialState = {
  program_data: [],
  isLoading: false,
};

const jackRabbitReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_JACKRABBIT_PROGRAMS:
      return { ...state, program_data: action.payload };
    default:
      return state;
  }
};

export default jackRabbitReducer;
