import React from "react";
import "./staffSection.css";
import StaffProfileCard from "components/StaffProfileCard/staffProfileCard";
import defaultStaffPhoto from "assets/defaultStaffPhoto.jpeg";
import { useSelector } from "react-redux";

const StaffSection = () => {
  const staff = useSelector((state) => state.contentfulReducer.staff);
  return (
    <div className="staff-section-container">
      {staff.map(
        (member, key) =>
          member.fields.showOnHome && (
            <StaffProfileCard
              key={key}
              media={
                member.fields.staffPhoto?.fields.file.url || defaultStaffPhoto
              }
              name={member.fields.name}
              position={member.fields.jobTitle}
              bio={member.fields.bio}
            />
          )
      )}
    </div>
  );
};

export default StaffSection;
