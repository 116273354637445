import React, { useState } from "react";
import "./staffProfileCard.css";

function StaffProfileCard(props) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = (staffName) => {
    // ReactGA.event({
    //   category: "Staff Profile Card",
    //   action: "Click",
    //   label: staffName,
    //   eventAction: staffName,
    // });
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`card-container ${isFlipped ? "flipped" : ""}`}
      onClick={() => handleClick(props.name)}
    >
      <div className="front">
        <div className="card-top">
          <img
            src={props.media}
            alt="employee_picture"
            width="200"
            height="200"
          />
        </div>
        <div className="card-bottom">
          <h3 className="card-header">{props.name}</h3>
          <p className="card-details">{props.position}</p>
        </div>
      </div>
      <div className="back">
        <p className="bio-p">
          <b>Bio:</b>
        </p>
        {props.bio ? props.bio : "No bio available."}
      </div>
    </div>
  );
}

export default StaffProfileCard;
