import React from "react";
import CarouselJumbo from "../../components/Carousel/carousel";
import AboutSection from "../../components/AboutSection/AboutSection";
import ProgramSection from "../../components/ProgramSection/programSection";
import MidPageBreak from "../../components/MidPageBreak/midPage.js";
import StaffSection from "../../components/StaffSection/staffSection.js";
import { Helmet } from "react-helmet";
import "./Home.css";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Ace Gymnastics</title>
          <meta
            name="Ace Gymnastics"
            content="Ace Gymnastics is a premier gymnastics facility located in Bardstown, Kentucky. Our experienced coaches provide high-quality gymnastics training to children of all ages and skill levels. Come join our fun and supportive community and take your gymnastics skills to the next level."
          />
        </Helmet>
        <CarouselJumbo />
        <div className="about-section-parent">
          <AboutSection />
        </div>
        <div className="program-section-parent">
          <ProgramSection />
        </div>
        <div className="midpage-section-parent">
          <MidPageBreak
            title="Already have an account? Access online portal here."
            buttonContent={{
              title: "Parent Portal",
              href: "https://app.jackrabbitclass.com/jr3.0/ParentPortal/Login?orgID=539177",
              target: "_blank",
              rel: "noopener noreferrer",
            }}
          />
        </div>
        <div className="staff-section-parent">
          <StaffSection />
        </div>
      </div>
    );
  }
}

export default Home;
