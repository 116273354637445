import React, { useState, useEffect } from "react";

function LCPImage(props) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };
    img.src = props.src;
  }, [props.src]);

  return (
    <div style={{ display: loaded ? "block" : "none" }}>
      <img
        src={props.src}
        alt={props.alt}
        width={props.width}
        height={props.height}
        className={props.className}
      />
    </div>
  );
}

export default LCPImage;
