import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@material-ui/core";
import { convertProgramNameString } from "helpers/dataConversion/convertProgramNameString";
import { convertDaysOffered } from "helpers/dataConversion/convertDaysOffered";
import { convertTimeOffered } from "helpers/dataConversion/convertTimeOffered";
import { convertURLEncoded } from "helpers/dataConversion/convertURLEncoded";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(160, 36, 36)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const trackEventGA = (value) => {
  // ReactGA.event({
  //   category: "Programs Table",
  //   action: "Click",
  //   label: `${value.id} - ${value.name} - ${value.time} - ${value.day}`,
  //   eventAction: value,
  // });
};

export default function CustomizedTables(props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Register</StyledTableCell>
            <StyledTableCell align="left">Class</StyledTableCell>
            <StyledTableCell align="left">Days</StyledTableCell>
            <StyledTableCell align="left">Times</StyledTableCell>
            <StyledTableCell align="left">Tuition</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, key) => (
            <StyledTableRow key={key}>
              <StyledTableCell component="th" scope="row">
                {row.online_reg_link && (
                  <a
                    href={`${convertURLEncoded(row.online_reg_link)}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      onClick={() =>
                        trackEventGA({
                          name: row.name,
                          id: row.id,
                          time: `${row.start_time} ${row.end_time}`,
                          day: row.meeting_days,
                        })
                      }
                      size="small"
                      variant={
                        row.openings.calculated_openings > 0
                          ? "contained"
                          : "outlined"
                      }
                      style={{
                        maxWidth: "83px",
                        backgroundColor: `${
                          row.openings.calculated_openings > 0
                            ? "rgb(160, 36, 36)"
                            : "white"
                        }`,
                        color: `${
                          row.openings.calculated_openings > 0
                            ? "white"
                            : "rgb(160, 36, 36)"
                        }`,
                        borderColor: `${
                          row.openings.calculated_openings > 0
                            ? "white"
                            : "rgb(160, 36, 36)"
                        }`,
                      }}
                    >{`${
                      row.openings.calculated_openings > 0
                        ? "Register"
                        : "Waitlist"
                    }`}</Button>
                  </a>
                )}
              </StyledTableCell>
              <StyledTableCell align="left">
                {convertProgramNameString(row.name)}
              </StyledTableCell>
              {/* Need to create a helper function that shows which days this is offered. Try to use meeting_days object */}
              <StyledTableCell align="left">
                {convertDaysOffered(row.meeting_days)}
              </StyledTableCell>
              {/* Need to create a helper function that takes the 24 hour time and converts to a readable 12 hour time. Parameters will be row.start_time and row.end_time */}
              <StyledTableCell align="left">
                {convertTimeOffered(
                  row.start_time,
                  row.end_time,
                  row.meeting_days
                )}
              </StyledTableCell>
              <StyledTableCell align="left">{`$${row.tuition.fee}`}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
