import BannerTitle from "components/BannerTitle/bannertitle";
import "./careerPage.css";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";
import MidPageBreak from "components/MidPageBreak/midPage";

const CareerPage = () => {
  const career = useSelector((state) => state.contentfulReducer.career);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const renderJoinOurTeam = (section) => {
    return (
      <div className="employment">
        <div className="employment-text">
          <h2>{section?.title}</h2>
          <p>{section?.description}</p>
          <h2>Investing in You</h2>
          <p>
            At Ace Gymnastics, we believe in the power of personal and
            professional growth. We are dedicated to fostering an environment
            where every team member can thrive and achieve their full potential.
            Through hands-on training, mentorship programs, and access to the
            latest industry resources, we ensure that you have everything you
            need to succeed. Join us and experience a workplace where your
            growth is our priority.
          </p>
        </div>
        {section?.image && (
          <div className="employment-image">
            <img src={section?.image?.fields?.file?.url} alt={section?.title} />
          </div>
        )}
      </div>
    );
  };

  const renderOurValues = (section) => {
    return (
      <div className="our-values">
        <h2>{section?.title}</h2>
        <ul>
          {section?.values.map((item, index) => (
            <li key={index} className="value-item">
              <span className="value-title">{item.value}</span>
              <span className="value-description">{item.description}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Ace Gymnastics Employment</title>
        <meta name="description" content="Join Our Team at Ace Gymnastics" />
        <meta property="og:title" content="Ace Gymnastics Employment" />
        <meta
          property="og:description"
          content="Join Our Team at Ace Gymnastics"
        />
        <meta property="og:image" content="https://i.imgur.com/UKJjqNH.png" />
        <meta
          property="og:url"
          content="https://www.ace-gymnastics.com/employment"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <BannerTitle title={career?.title} />
      {renderJoinOurTeam(career?.sections[0])}
      <MidPageBreak
        title="Ready to Make an Impact?"
        buttonContent={{ title: "View Details", href: "/job-application" }}
      />
      {renderOurValues(career.sections[1])}
    </div>
  );
};

export default CareerPage;
