import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-section contact">
        <h4>Contact:</h4>
        <p>
          <a href="tel:502-252-8512">502-252-8512</a>
        </p>
        {/* create an a tag with mailto */}
        <p>
          <a href="mailto:office@ace-gymnastics.com">
            office@ace-gymnastics.com
          </a>
        </p>
        <p>2388 New Haven Road</p>
        <p>Bardstown, KY 40004</p>
      </div>
      <div className="footer-section about-us">
        <h3>
          <em>ACE Gymnastics where kids are Active, Confident, Empowered!</em>
        </h3>
      </div>
      <div className="footer-section social">
        <h4 className="follow">Follow Us:</h4>
        <div className="social-links">
          <a
            href="https://www.facebook.com/ACE-Gymnastics-101806134601903/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="visit our facebook page"
          >
            <FontAwesomeIcon icon={faFacebookSquare} color="white" size="2x" />
          </a>
          <a
            href="https://www.instagram.com/ace.gymnastics/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="visit our instagram page"
          >
            <FontAwesomeIcon icon={faInstagram} color="white" size="2x" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
