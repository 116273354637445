import BannerTitle from "components/BannerTitle/bannertitle";
import ProgramDescription from "components/ProgramDescription/programDescription";
import ProgramSection from "components/ProgramSection/programSection";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./programDetails.css";
import ReactGA from "react-ga";

const ProgramDetails = () => {
  const displayProgramNameWithAppendedClassText = useSelector(
    (state) => state.navigationReducer.displayProgramNameWithAppendedClassText
  );
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="program-details-parent">
      <BannerTitle
        title={
          !!displayProgramNameWithAppendedClassText
            ? `${displayProgramNameWithAppendedClassText}`
            : "Programs"
        }
      />
      <Routes>
        {/* Use this path in case a user navigates manually to /program-details */}
        <Route path="/" element={<ProgramSection />} />
        <Route path="/:programtype" element={<ProgramDescription />} />
      </Routes>
    </div>
  );
};

// Can loop through reusable component. The component can query for jackrabbit based on the end of the URL. We can make sure to use the jackrabbitSlug

export default ProgramDetails;
