export const convertTimeOffered = (start_time, end_time, days) => {
  // check if parameters are undefined
  if (
    start_time === undefined ||
    end_time === undefined ||
    days === undefined
  ) {
    return;
  }
  const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const daysBeingOffered = [];
  daysOfWeek.forEach((day) => {
    if (days[day]) {
      daysBeingOffered.push(day.charAt(0).toUpperCase() + day.slice(1));
    }
  });
  if (daysBeingOffered.length > 1) {
    return "Varies";
  } else {
    let convertStartTime = start_time.split(":")[0] % 12;
    let convertEndTime = end_time.split(":")[0] % 12;
    let decideIfAmPm = Number(start_time.split(":")[0]) >= 12 ? "pm" : "am";
    let newStartTimeString = `${
      convertStartTime === 0 ? 12 : convertStartTime
    }:${start_time.split(":")[1]}${decideIfAmPm}`;
    let newEndTimeString = `${convertEndTime === 0 ? 12 : convertEndTime}:${
      end_time.split(":")[1]
    }${decideIfAmPm}`;
    return `${newStartTimeString} - ${newEndTimeString}`;
  }
};
