import BannerTitle from "components/BannerTitle/bannertitle";
import StaffProfileCard from "components/StaffProfileCard/staffProfileCard";
import React, { useEffect } from "react";
import defaultStaffPhoto from "assets/defaultStaffPhoto.jpeg";
import "./staff.css";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const Staff = () => {
  const staff = useSelector((state) => state.contentfulReducer.staff);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Ace Gymnastics Staff</title>
        <meta
          name="description"
          content="Meet our professional and passionate staff, ready to guide you through your gymnastics journey."
        />
        <meta property="og:title" content="Ace Gymnastics Staff" />
        <meta
          property="og:description"
          content="Meet our professional and passionate staff, ready to guide you through your gymnastics journey."
        />
        <meta property="og:image" content="https://i.imgur.com/UKJjqNH.png" />
        <meta
          property="og:url"
          content="https://www.ace-gymnastics.com/staff"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <BannerTitle title="Staff" />
      <div className="staff-responsive staff-container">
        {staff.map((member, key) => {
          return (
            member.fields.display && (
              <div className="staff-card-container" key={key}>
                <StaffProfileCard
                  name={member.fields.name}
                  position={member.fields.jobTitle}
                  media={
                    member.fields?.staffPhoto?.fields?.file?.url ||
                    defaultStaffPhoto
                  }
                  bio={member.fields.bio}
                />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default Staff;
