import axios from "axios";

export const fetchCat2Programs = (filters) => {
  return axios({
    method: "POST",
    url: "https://ace-gymnastics-api.herokuapp.com/programs",
    // url: "http://localhost:9000/programs",
    data: {
      OrgId: process.env.REACT_APP_ORGID,
      ShowClosed: true,
      ...filters,
    },
  })
    .then((result) => {
      return result.data.rows;
    })
    .catch((error) => {
      console.log("catch", error);
    });
};
