// create a function that splits a parameter which is a string by semicolon and returns it as an array
export const convertContentfulNotesFromString = (string) => {
  // check if string is undefined
  if (string === undefined) {
    return;
  }
  const notesArray = string.split(";");

  return notesArray;
};
