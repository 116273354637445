import logofull from "assets/logo.webp";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./navbar.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import calendar2024 from "assets/calendar2024.pdf";
import { useSelector } from "react-redux";

function NavBar() {
  const [expanded, setExpanded] = useState(false);

  const scrollToTopAndCloseNavBar = async () => {
    await setExpanded(false);
    window.scrollTo(0, 0);
  };

  const programTitles = useSelector(
    (state) => state.contentfulReducer.programTitles
  );
  return (
    <Navbar
      className="nav-bar"
      collapseOnSelect={true}
      expand="lg"
      variant="dark"
      // sticky="top"
      expanded={expanded}
    >
      <Container>
        <Link
          style={{ textDecoration: "none" }}
          to="/"
          className="logo-container"
        >
          <img
            alt="ace_gymnastics_logo"
            src={logofull}
            width="720"
            height="720"
            className="logo"
          />
        </Link>
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
          aria-controls="responsive-navbar-nav"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={scrollToTopAndCloseNavBar} as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link onClick={scrollToTopAndCloseNavBar} as={Link} to="/staff">
              Staff
            </Nav.Link>
            <NavDropdown title="Programs" id="collasible-nav-dropdown">
              {programTitles.map((program, key) => {
                return (
                  <NavDropdown.Item
                    onClick={scrollToTopAndCloseNavBar}
                    key={key}
                    as={Link}
                    to={`/programs/program-details/${program.fields.displayName}`}
                  >
                    {program.fields.displayName}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
            <NavDropdown title="Events" id="collasible-nav-dropdown">
              <NavDropdown.Item
                onClick={scrollToTopAndCloseNavBar}
                as={Link}
                to={`/events`}
              >
                Events
              </NavDropdown.Item>
              {/* Add this in when Bourbon City Spectacular Meet page is ready */}
              {/* <NavDropdown.Item
                onClick={scrollToTopAndCloseNavBar}
                as={Link}
                to={`/bourbon-city-spectacular`}
              >
                Bourbon City Spectacular
              </NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link href={calendar2024} target="_blank">
              Calendar
            </Nav.Link>
            <Nav.Link
              onClick={scrollToTopAndCloseNavBar}
              as={Link}
              to="/employment"
            >
              Employment
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              href={`https://app.jackrabbitclass.com/regv2.asp?id=${process.env.REACT_APP_ORGID}`}
              target="_blank"
            >
              Register
            </Nav.Link>
            <Nav.Link
              href="https://app.jackrabbitclass.com/jr3.0/ParentPortal/Login?orgID=539177"
              target={"_blank"}
            >
              Parent Portal
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
