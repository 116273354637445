export const convertDaysOffered = (days) => {
  // check if days is undefined
  if (days === undefined) {
    return;
  }
  const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const daysBeingOffered = [];
  daysOfWeek.forEach((day) => {
    if (days[day]) {
      daysBeingOffered.push(day.charAt(0).toUpperCase() + day.slice(1));
    }
  });
  if (daysBeingOffered.length > 1) {
    return daysBeingOffered.join(", ");
  } else if (daysBeingOffered.length === 1) {
    return daysBeingOffered.join("");
  }
};
