import React from "react";
import bardstownfloor from "assets/bardstownfloor.webp";
import "./aboutsection.css";

const AboutSection = () => {
  return (
    <div className="home-about">
      <div className="about-section">
        <h2>About Us</h2>
        <p className="desktop-about">
          Welcome to our family-owned gymnastics facility located in the heart
          of Bardstown, KY! We are dedicated to providing a safe and supportive
          environment for athletes of all levels to learn and grow from 18
          months to 18 years of age. We offer a wide range of programs,
          including recreational classes, competitive team training, tumbling
          classes, and private lessons. Our curriculum is designed to improve
          strength, flexibility, coordination, and body awareness, while
          building self-confidence, teamwork, and a love for physical activity.
          We believe that gymnastics is more than just a sport; it's a way of
          life that fosters discipline, perseverance, and a growth mindset. We
          are committed to creating a welcoming environment where every athlete
          feels like a part of our family and are dedicated to helping our
          students reach their full potential in gymnastics and beyond.
        </p>
        <p className="mobile-about">
          Welcome to our family-owned gymnastics facility in Bardstown, KY! We
          offer safe and supportive programs for athletes aged 18 months to 18
          years, building strength, coordination, and self-confidence through
          recreational and competitive classes, tumbling, and private lessons.
          Our curriculum fosters discipline, teamwork, and growth mindset,
          creating a welcoming environment where every athlete feels like family
          and reaches their full potential.
        </p>
        {/* <button className="button account-button">View More</button> */}
      </div>
      <div className="about-img-section">
        <img
          src={bardstownfloor}
          alt="gym-floor"
          className="home-about-image"
          width="600"
          height="600"
        />
      </div>
    </div>
  );
};

export default AboutSection;
